import Button from "@material-ui/core/Button"
import { BSON } from "mongodb-stitch-browser-sdk"
import { useSnackbar } from "notistack"
import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"

import { ListTable } from "../../../../components/ListTable"
import { IAccountRecord, AccountFactory } from "../../../../models/Account"
import { useAppSelector } from "../../../../store"
import { getCurrentBudget } from "../../../../store/layout"
import { useApi } from "../../../../utils/api"

import { AccountForm } from "./AccountForm"

export const List: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const api = useApi()

  const currentBudgetId = useSelector(getCurrentBudget)

  const accounts = api.collectionAccessor("accounts", (account) => account.budgetId.toHexString() === currentBudgetId)
  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const form = useRef<AccountForm>(null)

  async function addItem() {
    if (currentUser) {
      if (form.current) {
        await form.current.ask(AccountFactory(), "New Account", async (data) => {
          await api.createRecord("accounts", { ...data, budgetId: new BSON.ObjectID(currentBudgetId), user_id: currentUser.id })
        })
      }
    }
  }

  async function removeItem(item: IAccountRecord) {
    await api.removeRecord("accounts", item._id)
  }

  async function editItem(item: IAccountRecord) {
    if (currentUser) {
      if (form.current) {
        await form.current.ask(item, "Edit Account", async (data) => {
          await api.updateRecord("accounts", item._id, data)
        })
      }
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        await api.collectionSync("accounts")
      } catch (error) {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, { variant: "error" })
      }
    }

    loadData()
  }, [])

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await api.fetchFromCollection("accounts", { query: { budgetId: new BSON.ObjectID(currentBudgetId) } })
        setLoading(false)
      } catch (error) {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, { variant: "error" })
      }
    }

    loadData()
  }, [currentBudgetId])

  return (
    <div>
      <h2>Accounts</h2>
      {loading && <div>Loading ...</div>}
      <AccountForm ref={form} />
      <ListTable
        columns={[
          { key: "name", label: "Name" },
          { key: "actions", label: "Actions" },
        ]}
        data={accounts}
        renderCell={{
          actions: (row) => (
            <>
              <Button onClick={() => removeItem(row.item)}>Remove</Button>
              <Button onClick={() => editItem(row.item)}>Edit</Button>
            </>
          ),
        }}
      />
      <button onClick={addItem}>Add</button>
    </div>
  )
}
