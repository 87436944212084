import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import LinearProgress from "@material-ui/core/LinearProgress"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import { Formik, Form, FormikHelpers, FormikErrors } from "formik"
import React from "react"
import * as yup from "yup"

function flattenErrors(errors: FormikErrors<any>, result: string[] = []): string[] {
  for (const key of Object.keys(errors)) {
    if (typeof errors[key] === "string") {
      result.push(errors[key] as string)
    } else {
      flattenErrors(errors[key] as FormikErrors<any>, result)
    }
  }

  return result
}

export const ErrorAlert: React.FC<{ errors: FormikErrors<any> }> = (props) => {
  const flatErrors = flattenErrors(props.errors)

  function keyFromMessage(message: string) {
    const match = message.match(/^\S+/)
    if (match) {
      return match[0]
    } else {
      return ""
    }
  }

  return (
    <>
      {flatErrors.length > 0 && (
        <Alert severity="warning">
          <AlertTitle>Validation Errors</AlertTitle>
          <List dense>
            {flatErrors.map((message) => (
              <ListItem key={keyFromMessage(message)}>{message}</ListItem>
            ))}
          </List>
        </Alert>
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export abstract class BaseForm<DT extends object> extends React.Component<
  unknown,
  {
    visible: boolean
    title: string
    data: Partial<DT>
  }
> {
  protected validationSchema?: yup.ObjectSchema<Partial<DT>>

  private submitPromise?: { resolve: (data: Partial<DT>) => void; reject: (error: any) => void }
  private onSubmit?: (data: DT) => Promise<void>

  constructor(props: any) {
    super(props)
    this.state = {
      visible: false,
      title: "",
      data: {},
    }
  }

  public ask(initialValue: Partial<DT>, title: string, onSubmit: (data: DT) => Promise<void>) {
    return new Promise((resolve, reject) => {
      this.submitPromise = { resolve, reject }
      this.onSubmit = onSubmit
      this.setState({ visible: true, title, data: { ...initialValue } })
    })
  }

  public render() {
    return (
      <>
        <Dialog onEscapeKeyDown={() => this.onFormAbort()} open={this.state.visible} onClose={() => this.onFormAbort()}>
          <Formik
            initialValues={this.state.data}
            onSubmit={(values, form) => this.onFormSubmit(values, form)}
            validate={this.validate && ((values: Partial<DT>) => this.validate(values))}
            validationSchema={this.validationSchema}
          >
            {(form) => (
              <>
                <ErrorAlert errors={form.errors} />

                <DialogTitle>{this.state.title}</DialogTitle>
                <DialogContent>
                  <Form onSubmit={form.handleSubmit}>{this.renderForm()}</Form>
                </DialogContent>
                <DialogActions>
                  {form.isSubmitting && <LinearProgress />}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={form.isSubmitting}
                    onClick={async () => {
                      form.setErrors(await form.validateForm(form.values))
                      await form.submitForm()
                    }}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => this.onFormAbort()} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </>
            )}
          </Formik>
        </Dialog>
      </>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  protected validate(values: Partial<DT>): FormikErrors<Partial<DT>> | Promise<FormikErrors<Partial<DT>>> {
    return {}
  }

  private onFormAbort() {
    this.setState({ visible: false })
    if (this.submitPromise) {
      this.submitPromise.reject("aborted")
    }
  }

  private async onFormSubmit(values: Partial<DT>, form: FormikHelpers<Partial<DT>>) {
    form.setSubmitting(true)
    if (this.onSubmit) {
      await this.onSubmit(values as DT)
    }
    form.setSubmitting(false)
    this.setState({ visible: false })
    if (this.submitPromise) {
      this.submitPromise.resolve(values)
    }
  }

  abstract renderForm(): JSX.Element
}
