import React from "react"

export const MainIndexPage: React.FC = () => {
  return (
    <div>
      <h2>Welcome to Budget!</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia aliquam repudiandae impedit eius provident facilis
        debitis inventore laboriosam nihil at, obcaecati rerum consequatur iste eveniet quidem eligendi temporibus ea odio?
      </p>
    </div>
  )
}
