import Divider from "@material-ui/core/Divider"
import * as icons from "@material-ui/icons"
import React from "react"

import { SideMenuGroup } from "./SideMenuGroup"
import { SideMenuItem } from "./SideMenuItem"

export const MainMenu: React.FC = () => {
  return (
    <>
      <SideMenuItem exact label="Home" Icon={icons.Home} to="/" />

      <Divider />

      <SideMenuGroup label="Settings" to="/settings">
        <SideMenuItem label="Budgets" to="/settings/budgets" />
        <SideMenuItem label="Accounts" to="/settings/accounts" />
        <SideMenuItem label="Projections" to="/settings/projections" />
      </SideMenuGroup>
    </>
  )
}
