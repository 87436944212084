import { ObjectID } from "bson"
import { createAction, createReducer } from "typesafe-actions"

interface StitchModel {
  _id: ObjectID
}

interface IUpdateCollectionAction<CNT extends string = string, RT extends StitchModel = StitchModel> {
  type: "api/UPDATE_COLLECTION"
  payload: {
    collection: CNT
    records: RT[]
  }
}

interface IRemoveItemFromCollection<CNT extends string = string> {
  type: "api/REMOVE_ITEM_FROM_COLLECTION"
  payload: {
    collection: CNT
    ids: ObjectID[]
  }
}

export const updateCollection = createAction("api/UPDATE_COLLECTION", (action) => {
  return <RT extends { [key in string]: StitchModel }>(collection: keyof RT, records: RT[typeof collection][]) =>
    action({ collection, records })
})

export const removeItemFromCollection = createAction("api/REMOVE_ITEM_FROM_COLLECTION", (action) => {
  return <RT extends { [key in string]: StitchModel }>(collection: keyof RT, ids: ObjectID[]) => action({ collection, ids })
})

export function createApiReducer<K extends string, RT extends { [key in K]: StitchModel }>() {
  interface State {
    readonly collections: {
      [key in keyof RT]?: RT[key][]
    }
  }

  type AnyType = keyof RT
  const initialState: State = {
    collections: {},
  }

  type AnyActionType = IUpdateCollectionAction<Extract<AnyType, string>> | IRemoveItemFromCollection<Extract<AnyType, string>>

  const apiReducer = createReducer<State, AnyActionType>(initialState)
    .handleAction("api/UPDATE_COLLECTION", (state, action) => {
      const { collection, records } = action.payload
      const theCollection = state.collections[collection] ? [...state.collections[collection]!] : []
      for (const record of records) {
        const found = theCollection.findIndex((r) => r._id.toHexString() === record._id.toHexString())
        if (found >= 0) {
          theCollection[found] = { ...record } as any
        } else {
          theCollection.push({ ...record } as any)
        }
      }
      const updatedCollections = { ...state.collections }
      updatedCollections[collection] = theCollection
      return { ...state, collections: updatedCollections }
    })
    .handleAction("api/REMOVE_ITEM_FROM_COLLECTION", (state, action) => {
      const { collection, ids } = action.payload
      let theCollection = state.collections[collection] ? [...state.collections[collection]!] : []
      theCollection = theCollection.filter((record) => {
        const isOnList = ids.find((id) => {
          return record._id.toHexString() === id.toHexString()
        })
        return !isOnList
      })
      const updatedCollections = { ...state.collections }
      updatedCollections[collection] = theCollection
      return { ...state, collections: updatedCollections }
    })

  return apiReducer
}
