import { RemoteMongoDatabase, BSON } from "mongodb-stitch-browser-sdk"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { ApiAccess, AppState } from "./ApiAccess"

interface StitchModel {
  _id: BSON.ObjectID
}

export function useApi<AS extends AppState, K extends string, RT extends { [key in K]: StitchModel }>(db: RemoteMongoDatabase) {
  const dispatch = useDispatch()
  const api = new ApiAccess<AS, K, RT>(dispatch, db)
  useEffect(() => {
    return () => {
      api.cleanup()
    }
  }, [])
  return api
}
