import { createBrowserHistory } from "history"
import React from "react"
import ReactDOM from "react-dom"

import { Main } from "./Main"
// import * as serviceWorker from './serviceWorker'
import configureStore from "./configureStore"

const history = createBrowserHistory()

const initialState = window.INITIAL_REDUX_STATE
const store = configureStore(history, initialState)

const container = document.createElement("div")
document.body.appendChild(container)

ReactDOM.render(<Main store={store} history={history} />, container)
