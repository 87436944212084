import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import { SvgIconComponent } from "@material-ui/icons"
import React from "react"
import { NavLink } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.action.selected,
    "& .MuiListItemIcon-root": {
      color: theme.palette.action.selected,
    },
  },
}))

interface SideMenuItemProps {
  to: string
  Icon?: SvgIconComponent
  label: string
  exact?: boolean
  className?: string
}

export const SideMenuItem: React.FC<SideMenuItemProps> = ({ to, Icon, label, exact, className }) => {
  const classes = useStyles()
  return (
    <ListItem button exact={exact} component={NavLink} to={to} activeClassName={classes.active} className={className}>
      {Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={label} />
    </ListItem>
  )
}
