import React from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"

import { List as AccountList } from "./accounts/List"
import { List as BudgetList } from "./budgets/List"
import { List as ProjectionList } from "./projections/List"

export const SettingsRouter: React.FC = () => {
  const { path } = useRouteMatch()

  const renderNotFound = () => <div>Not Found</div>

  const renderRoot = () => (
    <div>
      <h2>Settings</h2>
      Select a sub-section.
    </div>
  )

  return (
    <Switch>
      <Route exact path={`${path}`} component={renderRoot} />
      <Route exact path={`${path}/budgets`} component={BudgetList} />
      <Route exact path={`${path}/accounts`} component={AccountList} />
      <Route exact path={`${path}/projections`} component={ProjectionList} />

      <Route component={renderNotFound} />
    </Switch>
  )
}
