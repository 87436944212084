import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

export const BodyWrapper: React.FC = ({ children }) => {
  const location = useLocation<{ notice?: string } | undefined>()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (location?.state?.notice) {
      enqueueSnackbar(location.state.notice, { variant: "error" })
    }
  }, [location?.state?.notice])

  return <>{children}</>
}
