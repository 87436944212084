import Button from "@material-ui/core/Button"
import { useSnackbar } from "notistack"
import React, { useEffect, useState, useRef } from "react"

import { ListTable } from "../../../../components/ListTable"
import { BudgetFactory, IBudgetRecord } from "../../../../models/Budget"
import { useAppSelector } from "../../../../store"
import { useApi } from "../../../../utils/api"

import { BudgetForm } from "./BudgetForm"

export const List: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const api = useApi()
  const budgets = api.collectionAccessor("budgets")
  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const form = useRef<BudgetForm>(null)

  async function addItem() {
    if (currentUser) {
      if (form.current) {
        await form.current.ask(BudgetFactory(), "New Budget", async (data) => {
          await api.createRecord("budgets", { ...data, user_id: currentUser.id })
        })
      }
    }
  }

  async function removeItem(item: IBudgetRecord) {
    await api.removeRecord("budgets", item._id)
  }

  async function editItem(item: IBudgetRecord) {
    if (currentUser) {
      if (form.current) {
        await form.current.ask(item, "Edit Budget", async (data) => {
          await api.updateRecord("budgets", item._id, data)
        })
      }
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await Promise.all([api.fetchFromCollection("budgets"), api.collectionSync("budgets")])
        setLoading(false)
      } catch (error) {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, { variant: "error" })
      }
    }

    loadData()
  }, [])

  return (
    <div>
      <h2>Budgets</h2>
      {loading && <div>Loading ...</div>}
      <BudgetForm ref={form} />
      <ListTable
        columns={[
          { key: "name", label: "Name" },
          { key: "actions", label: "Actions" },
        ]}
        data={budgets}
        renderCell={{
          actions: (row) => (
            <>
              <Button onClick={() => removeItem(row.item)}>Remove</Button>
              <Button onClick={() => editItem(row.item)}>Edit</Button>
            </>
          ),
        }}
      />
      <button onClick={addItem}>Add</button>
    </div>
  )
}
