import { routerMiddleware } from "connected-react-router"
import { History } from "history"
import { Store, createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"

import { ApplicationState, createRootReducer } from "./store"

export default function configureStore(history: History, initialState: ApplicationState): Store<ApplicationState> {
  const composeEnhancers = composeWithDevTools({})

  const store = createStore(createRootReducer(history), initialState, composeEnhancers(applyMiddleware(routerMiddleware(history))))

  return store
}
