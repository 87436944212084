import { Field } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"

import { BaseForm } from "../../../../components/BaseForm"
import { IAccount, validationSchema } from "../../../../models/Account"

export class AccountForm extends BaseForm<IAccount> {
  constructor(props: any) {
    super(props)
    this.validationSchema = validationSchema
  }

  public renderForm() {
    return (
      <>
        <Field component={TextField} name="name" label="Name" />
      </>
    )
  }
}
