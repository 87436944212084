import Button from "@material-ui/core/Button"
import { BSON } from "mongodb-stitch-browser-sdk"
import { useSnackbar } from "notistack"
import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"

import { ListTable } from "../../../../components/ListTable"
import { ProjectionFactory, IProjectionRecord } from "../../../../models/Projection"
import { useAppSelector } from "../../../../store"
import { getCurrentBudget } from "../../../../store/layout"
import { useApi } from "../../../../utils/api"

import { ProjectionForm } from "./ProjectionForm"

export const List: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const api = useApi()
  const projections = api.collectionAccessor("projections")
  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const currentBudgetId = useSelector(getCurrentBudget)

  const form = useRef<ProjectionForm>(null)

  async function addItem() {
    if (currentUser) {
      if (form.current) {
        await form.current.ask(
          ProjectionFactory({ user_id: currentUser.id, budgetId: new BSON.ObjectID(currentBudgetId) }),
          "New Projection",
          async (data) => {
            await api.createRecord("projections", { ...data })
          }
        )
      }
    }
  }

  async function removeItem(item: IProjectionRecord) {
    await api.removeRecord("projections", item._id)
  }

  async function editItem(item: IProjectionRecord) {
    if (currentUser) {
      if (form.current) {
        await form.current.ask(item, "Edit Projection", async (data) => {
          await api.updateRecord("projections", item._id, data)
        })
      }
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        await api.collectionSync("projections")
      } catch (error) {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, { variant: "error" })
      }
    }

    loadData()
  }, [])

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true)
        await api.fetchFromCollection("projections", { query: { budgetId: new BSON.ObjectID(currentBudgetId) } })
        setLoading(false)
      } catch (error) {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, { variant: "error" })
      }
    }

    loadData()
  }, [currentBudgetId])

  return (
    <div>
      <h2>Projections</h2>
      {loading && <div>Loading ...</div>}
      <ProjectionForm ref={form} />
      <ListTable
        columns={[
          { key: "name", label: "Name" },
          { key: "type", label: "Type" },
          { key: "actions", label: "Actions" },
        ]}
        data={projections}
        renderCell={{
          actions: (row) => (
            <>
              <Button onClick={() => removeItem(row.item)}>Remove</Button>
              <Button onClick={() => editItem(row.item)}>Edit</Button>
            </>
          ),
        }}
      />
      <button onClick={addItem}>Add</button>
    </div>
  )
}
