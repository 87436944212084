import { connectRouter } from "connected-react-router"
import { History } from "history"
import { useSelector } from "react-redux"
import { combineReducers } from "redux"
import { StateType } from "typesafe-actions"

import { createApiReducer } from "../lib/apiReducer"

import { DataTypes } from "./api.types"
import { authReducer } from "./auth"
import { layoutReducer } from "./layout"

const apiReducer = createApiReducer<keyof DataTypes, DataTypes>()

export interface ApplicationState {
  auth: StateType<typeof authReducer>
  layout: StateType<typeof layoutReducer>
  api: StateType<typeof apiReducer>
}

export const createRootReducer = (history: History) =>
  combineReducers({
    auth: authReducer,
    layout: layoutReducer,
    api: apiReducer,
    router: connectRouter(history),
  })

export function useAppSelector<TSelected>(
  selector: (state: ApplicationState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) {
  return useSelector(selector, equalityFn)
}
