import { AnonymousCredential, GoogleRedirectCredential } from "mongodb-stitch-browser-sdk"
import { useSnackbar, ProviderContext } from "notistack"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"

import { setLogin } from "../store/auth"

import { client } from "./db"

class AuthAccess {
  constructor(private dispatch: Dispatch<any>, private enqueueSnackbar: ProviderContext["enqueueSnackbar"]) {}

  public handleInitialAuth() {
    if (client.auth.hasRedirectResult()) {
      client.auth
        .handleRedirectResult()
        .then((user) => {
          this.dispatch(setLogin(user, true))
          this.enqueueSnackbar(`Welcome ${user.profile.firstName}!`, { variant: "success" })
        })
        .catch((error) => console.error(error))
    } else {
      if (client.auth.isLoggedIn && client.auth.user) {
        this.dispatch(setLogin(client.auth.user, true))
        this.enqueueSnackbar(`Welcome back ${client.auth.user.profile.firstName}!`, { variant: "success" })
      }
    }
  }

  public async loginAnonymous() {
    const user = await client.auth.loginWithCredential(new AnonymousCredential())
    this.enqueueSnackbar(`Welcome!`, { variant: "success" })
    this.dispatch(setLogin(user, true))
  }

  public async loginGoogle() {
    const location = new URL(window.location.toString())
    location.pathname = "/"
    await client.auth.loginWithRedirect(new GoogleRedirectCredential(location.toString()))
  }

  public async logout() {
    await client.auth.logout()
    this.dispatch(setLogin(undefined, false))
    this.enqueueSnackbar(`Logged Out`, { variant: "success" })
  }
}

export function useAuth(): AuthAccess {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const auth = new AuthAccess(dispatch, enqueueSnackbar)
  return auth
}
