// import * as icons from "@material-ui/icons"
import { Home } from "@material-ui/icons"
import React from "react"

import { SideMenuItem } from "./SideMenuItem"

export const AnonymousMenu: React.FC = () => (
  <>
    <SideMenuItem label="Home" Icon={Home} to="/" />
  </>
)
