import { StitchUser } from "mongodb-stitch-browser-sdk"
import { createReducer, ActionType, createAction } from "typesafe-actions"

interface State {
  readonly currentUser: StitchUser | undefined
  readonly loggedIn: boolean
}

const initialState: State = {
  currentUser: undefined,
  loggedIn: false,
}

export const setCurrentUser = createAction("auth/SET_CURRENT_USER", (action) => (user: StitchUser) => action({ user }))
export const setLoggedIn = createAction("auth/SET_LOGGED_IN", (action) => (loggedIn: boolean) => action({ loggedIn }))
export const setLogin = createAction("auth/SET_LOGIN", (action) => (user: StitchUser | undefined, loggedIn: boolean) =>
  action({ user, loggedIn })
)

const actions = [setCurrentUser, setLoggedIn, setLogin]
type AnyActionType = ActionType<typeof actions[0]>

export const authReducer = createReducer<State, AnyActionType>(initialState)
  .handleAction(setCurrentUser, (state, action) => {
    return {
      ...state,
      currentUser: action.payload.user,
    }
  })
  .handleAction(setLoggedIn, (state, action) => {
    return { ...state, loggedIn: action.payload.loggedIn }
  })
  .handleAction(setLogin, (state, action) => {
    return { ...state, currentUser: action.payload.user, loggedIn: action.payload.loggedIn }
  })
