import Typography from "@material-ui/core/Typography"
import React from "react"

export const Footer: React.FC = () => (
  <div>
    <Copyright />
  </div>
)

const Copyright: React.FC = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Copyright © "}
    {/* <Link color="inherit" href="http://t-p-l.com"> */}
    Piotr Banasik
    {/* </Link> */} {new Date().getFullYear()}
    {"."}
  </Typography>
)
