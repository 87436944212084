import DateFnsUtils from "@date-io/date-fns"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { blueGrey } from "@material-ui/core/colors"
import { createMuiTheme, ThemeProvider, styled } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ConnectedRouter } from "connected-react-router"
import { History } from "history"
import { SnackbarProvider, useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { Store } from "redux"

import { Auth } from "./components/Auth"
import { BodyWrapper } from "./components/layout/BodyWrapper"
import { Footer } from "./components/layout/Footer"
import { Header } from "./components/layout/Header"
import Routes from "./routes"
import { ApplicationState, useAppSelector } from "./store"

const Root = styled("div")({
  display: "flex",
})

const AppBarSpacer = styled("div")(({ theme }) => theme.mixins.toolbar as any)

const Content = styled("div")({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
})

const MainContainer = styled(Container)({
  flexGrow: 1,
  overflow: "auto",
})

const CloseSnackbarButton: React.FC<{ keyValue: string | number }> = ({ keyValue }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <Button size="small" onClick={() => closeSnackbar(keyValue)}>
      Dismiss
    </Button>
  )
}

interface MainProps {
  store: Store<ApplicationState>
  history: History
}

function makeTheme(darkMode: boolean) {
  return createMuiTheme(
    darkMode
      ? {
          palette: {
            type: "dark",
            action: {
              selected: blueGrey[200],
            },
          },
        }
      : {
          palette: {
            type: "light",
            action: {
              selected: blueGrey[400],
            },
          },
        }
  )
}

export const Main: React.FC<MainProps> = ({ store, history }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

  const appTheme = makeTheme(prefersDarkMode)

  return (
    <ThemeProvider theme={appTheme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <PageTitle />
          <CssBaseline />
          <ConnectedRouter history={history}>
            <Root>
              <SnackbarProvider
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                maxSnack={6}
                action={(key) => {
                  return <CloseSnackbarButton keyValue={key} />
                }}
              >
                <Auth />
                <Header title="Budget" />
                <Content>
                  <AppBarSpacer />
                  <BodyWrapper>
                    <MainContainer maxWidth={false}>
                      <Routes />
                    </MainContainer>
                  </BodyWrapper>
                  <Footer />
                </Content>
              </SnackbarProvider>
            </Root>
          </ConnectedRouter>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  )
}

const PageTitle: React.FC = () => {
  const title = useAppSelector((state) => state.layout.title)

  useEffect(() => {
    document.title = title
  }, [title])

  return <></>
}
