import { ObjectID } from "bson"
import { RemoteMongoDatabase } from "mongodb-stitch-browser-sdk"
import { useSelector } from "react-redux"
import { Dispatch } from "redux"

import { updateCollection, removeItemFromCollection } from "./apiReducer"

export interface AppState {
  api: {
    collections: {
      [key in string]?: StitchModel[]
    }
  }
}

export type WithoutId<T extends any> = Omit<T, "_id">

interface StitchModel {
  _id: ObjectID
}

export class ApiAccess<AS extends AppState, K extends string, RT extends { [key in K]: StitchModel }> {
  private onCleanup: (() => void)[] = []

  constructor(private dispatch: Dispatch<any>, private db: RemoteMongoDatabase) {}

  public createRecord<T extends K>(collection: T, attributes: WithoutId<RT[typeof collection]>) {
    return this.db.collection<WithoutId<RT[typeof collection]>>(collection as any).insertOne({ ...attributes })
  }

  public removeRecord<T extends K>(collection: T, id: ObjectID) {
    return this.db.collection(collection).deleteOne({ _id: id })
  }

  public collectionSync<T extends K>(collection: T) {
    return this.setupSync(collection)
  }

  public updateRecord<T extends K>(collection: T, id: ObjectID, attributes: WithoutId<RT[typeof collection]>) {
    return this.db.collection(collection).updateOne({ _id: id }, attributes)
  }

  public async fetchFromCollection<T extends K>(collection: T, options: { query?: any } = {}) {
    const records = options.query
      ? await this.db
          .collection<RT[typeof collection]>(collection as any)
          .find(options.query)
          .toArray()
      : await this.db
          .collection<RT[typeof collection]>(collection as any)
          .find()
          .toArray()

    this.dispatch(updateCollection(collection as string, records as StitchModel[]))
  }

  public collectionAccessor<T extends K>(
    collection: T,
    filter: (value: RT[typeof collection]) => boolean = (_value: RT[typeof collection]) => true
  ): RT[typeof collection][] {
    return useSelector((state: AS) => {
      return state.api.collections[collection as any] ? state.api.collections[collection as any]!.filter(filter as any) : []
    }) as any
  }

  public cleanup() {
    for (const cb of this.onCleanup) {
      cb()
    }
  }

  private async setupSync<T extends K>(collection: T) {
    // TODO: support filtering
    const changeEventStream = await this.db.collection<RT[typeof collection]>(collection as any).watch()
    this.onCleanup.push(() => {
      changeEventStream.close()
    })
    changeEventStream.onNext((data) => {
      switch (data.operationType) {
        case "insert":
          if (data.fullDocument) {
            this.dispatch(updateCollection(data.namespace.collection, [data.fullDocument as StitchModel]))
          } else {
            console.error("insert event without fullDocument!")
          }
          break
        case "delete":
          this.dispatch(removeItemFromCollection(data.namespace.collection, [(data.documentKey as any)._id]))
          break
        case "replace":
          if (data.fullDocument) {
            this.dispatch(updateCollection(data.namespace.collection, [data.fullDocument as StitchModel]))
          } else {
            console.error("insert event without fullDocument!")
          }
          break
        case "update":
          if (data.fullDocument) {
            this.dispatch(updateCollection(data.namespace.collection, [data.fullDocument as StitchModel]))
          } else {
            console.error("insert event without fullDocument!")
          }
          break
        case "unknown":
          console.info(data)
          throw new Error(`unhandled operationType: ${data.operationType}`)
        default:
      }
    })
  }
}
