import React from "react"

export const AnonymousIndexPage: React.FC = () => {
  return (
    <div>
      <h2>Budget</h2>
      <p>Welcome to budget. Please log in.</p>
    </div>
  )
}
