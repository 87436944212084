import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import Select from "@material-ui/core/Select"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { styled } from "@material-ui/core/styles"
import { Menu, AccountCircle } from "@material-ui/icons"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { useAppSelector } from "../../store"
import { getCurrentBudget, setCurrentBudget } from "../../store/layout"
import { useApi } from "../../utils/api"
import { useAuth } from "../../utils/auth"

import { AnonymousMenu } from "./AnonymousMenu"
import { MainMenu } from "./MainMenu"

const MenuElement = styled("div")(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}))

const Spacer = styled("div")({
  flexGrow: 1,
  "&:after": {
    content: "&nbsp;",
  },
})

interface HeaderProps {
  title: string
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
  const [open, setOpen] = React.useState(false)

  const loggedIn = useAppSelector((state) => state.auth.loggedIn)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <AppBar position="absolute">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
            <Menu />
          </IconButton>
          <Typography variant="h6">{title}</Typography>
          {loggedIn ? <BudgetSelector /> : null}
          <Spacer />
          {loggedIn ? <ProfileMenu /> : <LoginLink />}
        </Toolbar>
      </AppBar>
      <Drawer onClose={handleDrawerClose} open={open}>
        {loggedIn ? <MainMenu /> : <AnonymousMenu />}
      </Drawer>
    </>
  )
}

const BudgetSelector: React.FC = () => {
  const api = useApi()
  const budgets = api.collectionAccessor("budgets")
  const dispatch = useDispatch()

  const budgetId = useSelector(getCurrentBudget)

  useEffect(() => {
    async function fetchData() {
      await api.fetchFromCollection("budgets")
    }
    fetchData()
  }, [])

  return (
    <MenuElement>
      <FormControl>
        <Select
          autoWidth
          displayEmpty
          value={budgetId}
          onChange={(event) => {
            dispatch(setCurrentBudget(event.target.value as string))
          }}
        >
          {budgets.map((budget) => (
            <MenuItem key={budget._id.toHexString()} value={budget._id.toHexString()}>
              {budget.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MenuElement>
  )
}

const LoginLink: React.FC = () => {
  const auth = useAuth()
  return (
    <>
      <Button color="inherit" onClick={() => auth.loginAnonymous()}>
        Login (Anon)
      </Button>
      <Button color="inherit" onClick={() => auth.loginGoogle()}>
        Login (Google)
      </Button>
    </>
  )
}

const ProfileMenu: React.FC = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const label = currentUser ? (currentUser.profile.name !== undefined ? currentUser.profile.name : "Logged in") : "Select User"
  const auth = useAuth()

  return (
    <>
      {label}
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event) => (anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget))}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Popper open={open} anchorEl={anchorEl}>
        <Paper>
          <MenuList>
            <MenuItem>Profile</MenuItem>
            <MenuItem>My account</MenuItem>
            <Divider />
            <MenuItem onClick={() => auth.logout()}>Log Out</MenuItem>
          </MenuList>
        </Paper>
      </Popper>
    </>
  )
}
