import * as React from "react"
import { Route, Switch } from "react-router-dom"

import { AnonymousIndexPage } from "./pages/anon/AnonymousIndexPage"
import { MainIndexPage } from "./pages/main/MainIndexPage"
import { SettingsRouter } from "./pages/main/settings/SettingsRouter"
import { useAppSelector } from "./store"

const Routes: React.FC = () => {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn)

  return (
    <>
      <Switch>
        {loggedIn ? (
          <>
            <Route exact path="/" component={MainIndexPage} />
            <Route path="/settings" component={SettingsRouter} />
          </>
        ) : (
          <>
            <Route exact path="/" component={AnonymousIndexPage} />
          </>
        )}

        <Route component={() => <div>Not Found</div>} />
      </Switch>
    </>
  )
}

export default Routes
