import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import { SvgIconComponent } from "@material-ui/icons"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import React, { useEffect } from "react"
import { useRouteMatch } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  indentChildren: {
    "& .MuiListItem-root": {
      paddingLeft: theme.spacing(4),
    },
  },
  active: {
    color: theme.palette.action.selected,
    "& .MuiListItemIcon-root": {
      color: theme.palette.action.selected,
    },
  },
}))

interface SideMenuGroupProps {
  Icon?: SvgIconComponent
  label: string
  to: string
}

export const SideMenuGroup: React.FC<SideMenuGroupProps> = ({ Icon, label, children, to }) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const handleClick = () => {
    setOpen(!open)
  }

  const match = useRouteMatch(to)

  useEffect(() => {
    setOpen(match !== null)
  }, [match])

  return (
    <>
      <ListItem button onClick={handleClick} className={match !== null ? classes.active : undefined}>
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.indentChildren}>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  )
}
