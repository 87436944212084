import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import React from "react"

interface IColumnInfo<KT extends string> {
  label?: string
  key: KT
}

interface IRenderRow<DT> {
  item: DT
}

interface ListTableProps<TC extends string, DT> {
  columns?: IColumnInfo<TC>[]
  data: DT[]
  renderCell?: Partial<Record<TC, (row: IRenderRow<DT>) => JSX.Element>>
}

export function ListTable<TC extends string, DT>({
  columns,
  data,
  renderCell,
}: ListTableProps<TC | Extract<keyof DT, string>, DT>) {
  let fixedColumns: IColumnInfo<TC | Extract<keyof DT, string>>[] = []
  if (columns === undefined) {
    if (data.length > 0) {
      fixedColumns = (Object.keys(data[0]) as Extract<keyof DT, string>[]).map((key) => ({ key }))
    } else {
      fixedColumns = []
    }
  } else {
    fixedColumns = columns
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {fixedColumns.map((col) => (
              <TableCell key={col.key}>{col.label ?? col.key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {fixedColumns.map((col) => (
                <TableCell key={col.key}>
                  {renderCell?.[col.key] !== undefined
                    ? (renderCell[col.key] as any)({ item: row })
                    : (row as any)[col.key]?.toString()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

// export const ListTable: <TC extends string, DT>React.FC<ListTableProps<TC, DT>> = () => {

// }
