import { BSON } from "mongodb-stitch-browser-sdk"
import * as yup from "yup"

import { RecordWithId } from "./types"

export const validationSchema = yup
  .object({
    user_id: yup.string().required(),

    name: yup.string().default("").required(),
    type: yup.string().default("").oneOf(["expense", "income"]).required(),
    amount: yup.number().default("").required(),
    startDate: yup.date().default(null).required(),
    budgetId: yup.object<BSON.ObjectID>().default(null).required(),
    repeat: yup
      .object({
        every: yup.number().default(null).required(),
        period: yup.string().default(null).oneOf(["day", "week", "month"]).required(),
        endDate: yup.date().default(null).optional(),
      })
      .default(null)
      .nullable(),
  })
  .defined()

export type IProjection = yup.InferType<typeof validationSchema>

export type IProjectionRecord = RecordWithId & IProjection

export function ProjectionFactory(data: Partial<IProjection> = {}): Partial<IProjection> {
  const defaults = validationSchema.default()
  return { ...defaults, ...data }
}
