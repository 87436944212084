import { BSON } from "mongodb-stitch-browser-sdk"
import * as yup from "yup"

import { RecordWithId } from "./types"

export const validationSchema = yup
  .object({
    user_id: yup.string().required(),
    budgetId: yup.object<BSON.ObjectID>().default(null).required(),
    name: yup.string().default("").required(),
  })
  .defined()

export type IAccount = yup.InferType<typeof validationSchema>

export type IAccountRecord = RecordWithId & IAccount

export function AccountFactory(data: Partial<IAccount> = {}): Partial<IAccount> {
  const defaults = validationSchema.default()
  return { ...defaults, ...data }
}
