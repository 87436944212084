import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { Field } from "formik"
import { TextField, Select } from "formik-material-ui"
import { DatePicker } from "formik-material-ui-pickers"
import React from "react"

import { BaseForm } from "../../../../components/BaseForm"
import { IProjection, validationSchema } from "../../../../models/Projection"

export const FieldWrapper: React.FC<{ label: string }> = (props) => {
  return (
    <FormControl>
      <InputLabel>{props.label}</InputLabel>
      {props.children}
    </FormControl>
  )
}

export const SelectField: React.FC<{ name: string; label: string }> = (props) => {
  return (
    <FieldWrapper label={props.label}>
      <Field component={Select} name={props.name}>
        {props.children}
      </Field>
    </FieldWrapper>
  )
}

export class ProjectionForm extends BaseForm<IProjection> {
  constructor(props: any) {
    super(props)
    this.validationSchema = validationSchema
  }

  public renderForm() {
    return (
      <>
        <div>
          <Field component={TextField} name="name" label="Name" />
        </div>
        <div>
          <SelectField name="type" label="Type">
            <MenuItem value={"expense"}>Expense</MenuItem>
            <MenuItem value={"income"}>Income</MenuItem>
          </SelectField>
        </div>
        <div>
          <Field component={TextField} name="amount" label="Amount" type="number" />
        </div>
        <div>
          <Field component={DatePicker} name="startDate" label="Start Date" clearable autoOk variant="dialog" />
        </div>
      </>
    )
  }
}
