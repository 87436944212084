import React, { useEffect } from "react"

import { useAuth } from "../utils/auth"

export const Auth: React.FC = () => {
  const auth = useAuth()
  useEffect(() => {
    auth.handleInitialAuth()
  }, [])
  return <></>
}
