import { createAction, ActionType, createReducer } from "typesafe-actions"

import { ApplicationState } from "."

interface State {
  title: string
  currentBudgetId?: string
}

function getInitialState(): State {
  const state: State = {
    title: "Budget",
  }

  const currentBudgetId = localStorage.getItem("currentBudgetId")
  state.currentBudgetId = currentBudgetId ?? undefined

  return state
}

export const setTitle = createAction("layout/SET_TITLE", (action) => (title: string) => action({ title }))
export const setCurrentBudget = createAction("layout/SET_CURRENT_BUDGET", (action) => (budgetId: string) => action({ budgetId }))

export const getCurrentBudget = (state: ApplicationState) => state.layout.currentBudgetId

const actions = [setTitle, setCurrentBudget]
type AnyActionType = ActionType<typeof actions[0]>

export const layoutReducer = createReducer<Readonly<State>, AnyActionType>(getInitialState())
  .handleAction(setTitle, (state, action) => ({
    ...state,
    title: action.payload.title,
  }))
  .handleAction(setCurrentBudget, (state, action) => {
    localStorage.setItem("currentBudgetId", action.payload.budgetId)

    return {
      ...state,
      currentBudgetId: action.payload.budgetId,
    }
  })
